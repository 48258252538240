import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventCard = _resolveComponent("EventCard")!
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.category_items)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.category_items, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-12 col-lg-4 event-item",
              key: index
            }, [
              _createVNode(_component_EventCard, {
                data: item,
                index: index
              }, null, 8, ["data", "index"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.category_items)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_el_empty, { description: "Nothing Found" })
        ]))
      : _createCommentVNode("", true)
  ]))
}