
import {computed, defineComponent, onMounted, ref, toRaw} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import store from "@/store";
import JwtService from "@/core/services/JwtService";
import {Actions} from "@/store/enums/StoreEnums";
import router from "@/router/router";
import {MenuComponent, ScrollComponent} from "@/assets/ts/components";
import EventCard from "@/components/events/EventCard.vue";
import {useHead} from "@vueuse/head";
let category_name = 'Category'

export default defineComponent({
  name: "category",
  components: {
    EventCard
  },
  setup() {
    useHead({
      // Can be static or computed
      title: computed(() => category_name),
      meta: [
        {
          name: `description`,
          content: computed(() => ''),
        },
      ],
    })
  },

  data() {

    return {
      category_items: {},
    }

  },
  methods: {
    getCategoryItems() {
      const id =this.$route.params.id;
      store.dispatch(Actions.GET_CATEGORY_BY_ID,id).then(response => {
        this.category_items = response.data;
        category_name = '123'
      }, error => {
        console.error("Got nothing from server. Please check internet connection and try again")
      });

    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.getCategoryItems()
      }
    }
  }

});
